<template>
  <div id="authentication_note" class="authentication_note" v-if="shownNote != null">
    <div><span v-html="shownNote"></span></div>
    <div v-if="!isSubUser" class="note_button">
      <el-button class="btn-blue" @click="goToSecurity" data-testid="goToSecurity">
        {{ $t('ibProfile.sharedIbList.enable') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { apiGetHint } from '@/resource';
import signHelper from '@/util/signinHelper';
export default {
  props: {},
  data() {
    return {
      shownNote: null
    };
  },
  computed: {
    isSubUser() {
      return this.$store.state.common.isSubUser;
    }
  },
  mounted() {
    this.getHint();
  },
  methods: {
    goToSecurity() {
      signHelper.signOut('backToClientPortal', '/securityManagement');
    },
    getHint() {
      apiGetHint().then(resp => {
        this.shownNote = resp.data.data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/security.scss';
</style>
