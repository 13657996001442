<template>
  <el-input v-bind="$attrs" v-on="$listeners" v-model="filteredValue"></el-input>
</template>
<script>
export default {
  name: 'ImportantNotesInput',
  model: {
    prop: 'inputValue',
    event: 'returnModel'
  },
  props: {
    inputValue: String
  },
  computed: {
    filteredValue: {
      get() {
        return this.inputValue;
      },
      set(newValue) {
        this.$emit('returnModel', newValue.replace(/"/g, ''));
      }
    }
  }
};
</script>
