<template>
  <el-dialog
    @keydown.enter.native.prevent
    id="verificationDialog"
    :visible.sync="visible"
    @close="closeCurrDialog"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <slot name="header"> </slot>
    </div>
    <VerificationComponent
      ref="verificationComponent"
      :action="action"
      @closeVeriDialog="closeDialog"
      :usedAuthTypes="usedAuthTypes"
      @confirm="confirmDialog"
    />
    <div slot="footer"></div>
  </el-dialog>
</template>

<script>
import { mapVerificationOperation, mapVariable } from '@/components/security/Security.js';
import VerificationComponent from '@/components/security/VerificationComponent.vue';

export default {
  name: 'VerificationDialog',
  props: {
    dialogVisible: {
      type: Boolean
    },
    usedAuthTypes: {
      type: Array
    },
    action: {
      type: String
    }
  },
  components: { VerificationComponent },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    }
  },

  methods: {
    closeCurrDialog() {
      this.$refs.verificationComponent.close();
    },
    closeDialog() {
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirmDialog() {
      this.$emit('confirm', this.usedMethod);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
#verificationDialog {
  .contact_method {
    color: $blue;
    text-align: center;
    padding-top: 30px;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-dialog {
    margin-top: 0vh !important;
    border-radius: 16px;
    text-align: start;
    max-width: 500px;
    height: auto;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }

  /deep/ .el-dialog__body {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    padding-bottom: 0px;
  }

  /deep/ .is-error {
    .el-form-item__content {
      .code_node {
        display: none;
      }
    }
  }
  // .requirement_box {
  //   font-weight: 400;
  //   font-size: 14px;
  //   padding-bottom: 20px;
  // }

  /deep/ .el-dialog__footer {
    padding: 0px;
    // div {
    //   div {
    //     display: flex;
    //     flex-direction: row;
    //     gap: 20px;
    //     .el-button {
    //       border-radius: 8px;
    //       width: 100%;
    //       font-weight: 500;
    //       font-size: 14px;
    //       padding: 11px;
    //     }
    //   }
    // }
  }
  .word_break {
    word-break: normal;
  }

  /deep/ .el-form-item__label {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 0 12px;
  }

  /deep/ .el-input__inner {
    height: 40px;
    padding-right: 55px;

    &:focus {
      border: 1px solid $blue;
    }
  }

  // .send_code {
  //   width: auto;
  //   text-align: center;
  //   cursor: pointer;
  //   border: none;
  //   background-color: transparent;
  // }

  /deep/ .el-form-item {
    margin-bottom: 20px;
  }

  /deep/ .el-input__suffix-inner {
    height: 100%;
    display: inline-grid;
  }
}
</style>
