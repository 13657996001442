const UNIONPAY = 4
const THAILANDBANKTRANSFER = 5
const MALAYSIABANKTRANSFER = 6
const FASAPAY = 7
const VIETNAMBANKTRANSFER = 8
const NIGERIABANKTRANSFER = 9
const INDIABANKTRANSFER = 24
const SKRILL = 31
const NETELLER = 32
const PAYPAL = 33
const SOUTHKOREABANKTRANSFER = 35
const BITWALLET = 37
const STICPAY = 38
const INDONESIABANKTRANSFER = 39
const PHILIPPINESBANKTRANSFER = 40
const SOUTHAFRICABANKTRANSFER = 42
const UGANDABANKTRANSFER = 50
const RWANDABANKTRANSFER = 51
const CAMEROONBANKTRANSFER = 54
const KENYABANKTRANSFER = 56
const GHANABANKTRANSFER = 57
const TANZANIABANKTRANSFER = 58
const PERFECTMONEY = 61
const JAPANBANKTRANSFER = 62
const MEXICOBANKTRANSFER = 63
const BRAZILBANKTRANSFER = 64
const NIGERIALOCALBANKTRANSFER = 67
const EBUY = 75
const INTERAC = 99
const PIX = 100
const TAIWANBANKTRANSFER = 102
const UAEBANKTRANSFER = 108
const AIRTM = 109
const EUBANKTRANSFER = 110


export const MULTIPLE_CURRENCY_CHANNELS = [SKRILL, NETELLER, STICPAY, BITWALLET, PERFECTMONEY, PAYPAL]

export const CURRENCY_RESTRICTED_CHANNELS = [
  UNIONPAY,
  THAILANDBANKTRANSFER,
  MALAYSIABANKTRANSFER,
  FASAPAY,
  VIETNAMBANKTRANSFER,
  NIGERIALOCALBANKTRANSFER,
  INDIABANKTRANSFER,
  INDONESIABANKTRANSFER,
  PHILIPPINESBANKTRANSFER,
  SOUTHAFRICABANKTRANSFER,
  STICPAY,
  BITWALLET,
  UGANDABANKTRANSFER,
  RWANDABANKTRANSFER,
  CAMEROONBANKTRANSFER,
  KENYABANKTRANSFER,
  GHANABANKTRANSFER,
  TANZANIABANKTRANSFER,
  PERFECTMONEY,
  PAYPAL,
  SOUTHKOREABANKTRANSFER,
  SKRILL,
  NETELLER,
  EBUY,
  PIX,
  BRAZILBANKTRANSFER,
  NIGERIABANKTRANSFER,
  JAPANBANKTRANSFER,
  INTERAC,
  AIRTM,
  UAEBANKTRANSFER,
  MEXICOBANKTRANSFER,
  TAIWANBANKTRANSFER,
  EUBANKTRANSFER,
]

export const CURRENCY_AVAILABLE_TYPES = {
  USD: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    STICPAY,
    BITWALLET,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    NIGERIALOCALBANKTRANSFER,
    INDIABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    SOUTHAFRICABANKTRANSFER,
    UGANDABANKTRANSFER,
    RWANDABANKTRANSFER,
    CAMEROONBANKTRANSFER,
    KENYABANKTRANSFER,
    GHANABANKTRANSFER,
    TANZANIABANKTRANSFER,
    PERFECTMONEY,
    PAYPAL,
    SOUTHKOREABANKTRANSFER,
    EBUY,
    SKRILL,
    NETELLER,
    PIX,
    BRAZILBANKTRANSFER,
    NIGERIABANKTRANSFER,
    JAPANBANKTRANSFER,
    AIRTM,
    UAEBANKTRANSFER,
    MEXICOBANKTRANSFER,
    TAIWANBANKTRANSFER,
  ],
  USC: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    NIGERIALOCALBANKTRANSFER,
    INDIABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    SOUTHAFRICABANKTRANSFER,
    UGANDABANKTRANSFER,
    RWANDABANKTRANSFER,
    CAMEROONBANKTRANSFER,
    KENYABANKTRANSFER,
    GHANABANKTRANSFER,
    TANZANIABANKTRANSFER,
    PERFECTMONEY,
    SOUTHKOREABANKTRANSFER,
    EBUY,
    SKRILL,
    NETELLER,
    PIX,
    BRAZILBANKTRANSFER,
    NIGERIABANKTRANSFER,
    JAPANBANKTRANSFER,
    STICPAY,
    BITWALLET,
    AIRTM,
    UAEBANKTRANSFER,
    MEXICOBANKTRANSFER,
    TAIWANBANKTRANSFER,
  ],
  CAD: [STICPAY, SKRILL, NETELLER, INTERAC],
  EUR: [PERFECTMONEY, STICPAY, BITWALLET, SKRILL, NETELLER, EUBANKTRANSFER],
  GBP: [STICPAY, SKRILL, NETELLER],
  JPY: [STICPAY, BITWALLET, JAPANBANKTRANSFER],
  NZD: [STICPAY],
  SGD: [STICPAY],
  AUD: [PAYPAL, STICPAY, NETELLER, BITWALLET],
  HKD: [STICPAY],
  TZS: [TANZANIABANKTRANSFER],
  KRW: [EBUY],
  BRL: [BRAZILBANKTRANSFER, PIX],
}
