<template>
  <el-form :model="form" label-position="top" :rules="rulesUnionPay" ref="withdrawFormUnionPayCps">
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.SelectWithdrawCard')" prop="bankCard">
            <el-select v-model="form.bankCard" @change="applyCardInformation" data-testid="bankCard">
              <el-option
                v-for="card in form.cards"
                :key="card.id"
                :value="card.id"
                :label="card.showNumber"
                :data-testid="card.showNumber"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankName"
              :readonly="true"
              data-testid="bankName"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.accountShowNumber"
              :readonly="true"
              data-testid="accountNumber"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.BankBranch')" prop="bankBranchName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankBranchName"
              :readonly="true"
              data-testid="bankBranchName"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BankAccountsName')" prop="bankAccountName">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bankAccountName"
              :readonly="true"
              data-testid="bankAccountName"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <ImportantNotesInput
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></ImportantNotesInput>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import ImportantNotesInput from '@/components/form/ImportantNotesInput';
import { apiCardInformation } from '@/resource';

export default {
  props: ['dialog'],
  components: { ImportantNotesInput },
  data() {
    return {
      chinaCities: [],
      form: {
        bankName: '',
        accountNumber: '',
        accountShowNumber: '',
        bankBranchName: '',
        bankAccountName: '',
        importantNotes: '',
        cards: [],
        bankCard: ''
      },
      rulesUnionPay: {
        bankCard: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankCard'),
            trigger: blur
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accNum'),
            trigger: 'blur'
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.branchName'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accName'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormUnionPayCps.validate();
    },
    queryCards() {
      return apiCardInformation(0)
        .then(resp => {
          if (resp.data.code == 0) {
            return resp.data.data;
          } else {
            return [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    applyCardInformation(id) {
      const card = this.form.cards.find(item => item.id == id);
      this.form.bankName = card.bank_name;
      this.form.bankBranchName = card.branch_name;
      this.form.accountShowNumber = card.showNumber;
      this.form.accountNumber = card.card_number;
      this.form.bankAccountName = card.card_holder_name;
    }
  },
  mounted() {
    this.queryCards().then(cards => {
      if (cards == null || cards.length == 0) {
        //display a warn dialog and redirect to client portal payment details
        this.$emit('update:dialog', {
          show: true,
          text: this.$t('withdraw.noCardWarn'),
          method: 'noCard'
        });
      } else {
        this.form.cards = cards;
        this.form.cards.forEach(card => {
          card.id = card.card_number;
          card.showNumber =
            card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        });
        this.form.bankName = cards[0].bank_name;
        this.form.bankBranchName = cards[0].branch_name;
        this.form.accountShowNumber = cards[0].showNumber;
        this.form.bankAccountName = cards[0].card_holder_name;
        this.form.accountNumber = cards[0].card_number;
        this.form.bankCard = cards[0].card_number;
      }
    });
  }
};
</script>
