<template>
  <el-form-item :label="$t(label ? label : 'common.field.country_region')" :prop="prop ? prop : 'country'">
    <el-select :value="value" @change="$emit('input', $event)" :disabled="disabled">
      <el-option
        v-for="item in list"
        :key="item.code"
        :selected="item == value"
        :value="item.code"
        :label="$t(item.nameKey)"
      ></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  props: {
    label: String,
    prop: String,
    value: String,
    list: Array,
    disabled: Boolean
  }
};
</script>
