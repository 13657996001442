<template>
  <el-form-item :label="label" :prop="name" :show-message="!showErrMsg" :rules="getRules">
    <el-input
      v-model="currentValue"
      v-on="listeners"
      v-bind="$attrs"
      :id="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :data-testid="testId || name"
      :maxlength="maxlength"
    />
    <slot></slot>
    <div v-if="showErrMsg" class="el-form-item__error">{{ getErrMsg }}</div>
  </el-form-item>
</template>

<script>
import { validateEmail } from '@/util/validators';

export default {
  name: 'dynamicRestrictInput',
  model: {
    prop: 'inputData',
    event: 'returnModel'
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    disabled: Boolean,
    testId: String,
    restrictionType: {
      type: String,
      default: null
    },
    maxlength: {
      type: String,
      default: null
    },
    inputData: {
      type: String | Number | Array | Object | Boolean,
      default: ''
    }
  },
  data() {
    return {
      focus: false,
      showErrMsg: false,
      isEmpty: false,
      emailRules: [
        {
          required: true,
          message: this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t('register.personalDetails.page1.email')
          }),
          trigger: 'blur'
        },
        {
          validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
          trigger: 'blur'
        }
      ],
      dynamicRules: [
        {
          required: true,
          message: this.$t('common.formValidation.common'),
          trigger: 'blur'
        }
      ],
      restrictionTypeMaps: {
        1: {
          reg: /^[a-zA-Z0-9]+$/g,
          msg: 'common.formValidation.AlphanumericOnlyNotSpace',
          replace: /[^a-zA-Z0-9]/g
        },
        2: {
          reg: /^[a-zA-Z0-9\s]+$/g,
          msg: 'common.formValidation.AlphanumericOnly',
          replace: /[^a-zA-Z0-9\s]/g
        },
        3: {
          reg: /^[0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/g,
          msg: 'common.formValidation.NumbersSymbol',
          replace: /[^0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g
        },
        4: {
          reg: /^[0-9]+$/g,
          msg: 'common.formValidation.NumbersOnlyNotSpace',
          replace: /[^0-9]/g
        },
        5: {
          reg: /^[0-9\s]*$/g,
          msg: 'common.formValidation.NumbersOnly',
          replace: /[^0-9\s]/g
        },
        6: {
          reg: /^[a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/g,
          msg: 'common.formValidation.LettersSymbol',
          replace: /[^a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g
        },
        7: {
          reg: /[a-zA-Z!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/g,
          msg: 'common.formValidation.LettersSymbolNotSpace',
          replace: /[^a-zA-Z!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g
        },
        8: {
          reg: /^[a-zA-Z]+$/g,
          msg: 'common.formValidation.LettersOnlyNotSpace',
          replace: /[^a-zA-Z]/g
        },
        9: {
          reg: /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/g,
          msg: 'common.formValidation.NotSpace',
          replace: /[^a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g
        }
      }
    };
  },
  computed: {
    openClass() {
      return this.$attrs.value || this.focus || this.placeholder || this.currentValue;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.focus = true),
        blur: event => (this.focus = false)
      });
    },
    currentValue: {
      get() {
        return this.inputData;
      },
      set(newValue) {
        let handleValue = this.checkValue(newValue);
        this.$emit('returnModel', handleValue);
        return handleValue;
      }
    },
    getRestrictionType() {
      if (this.restrictionType === 'email')
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.restrictionTypeMaps[this.restrictionType];
    },
    getRules() {
      if (this.restrictionType === 'email') return this.emailRules;
      else return this.dynamicRules;
    },
    getErrMsg() {
      const pattern = this.restrictionTypeMaps[this.restrictionType];
      return pattern && this.isEmpty == false
        ? this.$t(this.restrictionTypeMaps[this.restrictionType].msg)
        : this.$t('common.formValidation.common');
    }
  },
  methods: {
    checkValue(value) {
      const pattern = this.restrictionTypeMaps[this.restrictionType];
      if (value !== '') this.showErrMsg = this.isEmpty = false;
      if (pattern) {
        const reg = new RegExp(pattern.reg);
        if (reg && value !== '' && !reg.test(value)) {
          this.showErrMsg = true;
          this.isEmpty = false;
        } else this.showErrMsg = false;
        value = value.replace(pattern.replace, '');
      }
      if (value == '' && this.restrictionType !== 'email') this.showErrMsg = this.isEmpty = true;
      return value;
    }
  }
};
</script>
