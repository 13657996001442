import { apiAnti_Reuse } from '@/resource'
export default {
  data() {
    return {
      token: '',
    }
  },
  mounted() {
    this.fetchToken()
  },
  methods: {
    fetchToken() {
      return new Promise((resolve, reject) => {
        apiAnti_Reuse()
          .then(resp => {
            if (resp.data.code == 0) {
                this.token = resp.data.data
                resolve();
              } else {
                reject();
              }
          })
          .catch(err => {
            this.errorMessage(this.$t('withdraw.default.tokenExpired'))
          })
      })
    },
    errorMessage(message) {
      return this.$message({
        message: message,
        type: 'error',
      })
    },
  },
}
