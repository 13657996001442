import i18n from '@/lang/index'
import store from '@/store'

const mapVariable = {
  email: 'email',
  totp: 'totp',
  addTwoFactor: 'enable-auth-2fa',
  login: 'login',
  switchLoginEmail: 'login-email-close',
}

const mapVerificationOperation = (operation, emailAddress) => {
  console.log(operation)
  switch (operation) {
    case mapVariable['email']: {
      return {
        showCodeButton: true,
        preDesc: i18n.t('security.verifyPreEmailNote', {
          email: emailAddress,
        }),
        postDesc: i18n.t('security.verifyPostEmailNote', {
          email: emailAddress,
        }),
      }
    }
    default: {
      return {
        showCodeButton: false,
        preDesc: i18n.t('security.authenticatorTooltip'),
      }
    }
  }
}

const mapVerifiedAuth = (authType, verifiedAuths) => {
  //for showing verification dialog purpose (don't need for email)
  switch (authType) {
    case mapVariable['email']:
      return true
    case mapVariable['totp']:
      return verifiedAuths.filter(item => item == authType).length > 0
  }
}

const mapReverifiedBody = (factorAuth, resp) => {
  const respBody = resp?.data?.data
  const usedAuths = resp?.data?.code == 1201 ? respBody?.lackAuthMethods : respBody?.effectiveAuthMethods
  store.commit('common/setCheckedAuthStatus', respBody.authStatus)

  let usedRequiredAuths = store.state.common.requiredAuths
  usedRequiredAuths[factorAuth] =
    resp?.data?.code == 1201
      ? respBody?.effectiveAuthMethods.concat(respBody?.lackAuthMethods)
      : respBody?.effectiveAuthMethods
  store.commit('common/setRequiredAuths', usedRequiredAuths)
  return usedAuths.map(item => {
    return {
      type: item,
      authenticated: false,
      verified: mapVerifiedAuth(item, respBody.authStatus),
    }
  })
}

export { mapVerificationOperation, mapVariable, mapVerifiedAuth, mapReverifiedBody }
