export const countryIsoCode = [
  {
    id: 3290,
    name: 'Algeria',
    code: 'DZ',
  },
  {
    id: 3346,
    name: 'Argentina',
    code: 'AR',
  },
  {
    id: 6729,
    name: 'Armenia',
    code: 'AM',
  },
  {
    id: 3512,
    name: 'Australia',
    code: 'AU',
  },
  {
    id: 3588,
    name: 'Bahamas',
    code: 'BS',
  },
  {
    id: 7028,
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    id: 7046,
    name: 'Barbados',
    code: 'BB',
  },
  {
    id: 3781,
    name: 'Bolivia',
    code: 'BO',
  },
  {
    id: 3636,
    name: 'Brazil',
    code: 'BR',
  },
  {
    id: 6458,
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    id: 4160,
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    id: 4635,
    name: 'Cambodia',
    code: 'KH',
  },
  {
    id: 4575,
    name: 'Canada',
    code: 'CA',
  },
  {
    id: 4711,
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    id: 7010,
    name: 'Chile',
    code: 'CL',
  },
  {
    id: 1,
    name: 'China',
    code: 'CN',
  },
  {
    id: 4186,
    name: 'Columbia',
    code: 'CO',
  },
  {
    id: 4221,
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    id: 3981,
    name: 'Dominica',
    code: 'DM',
  },
  {
    id: 3982,
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    id: 4070,
    name: 'Ecuador',
    code: 'EC',
  },
  {
    id: 5961,
    name: 'El Salvador',
    code: 'SV',
  },
  {
    id: 6404,
    name: 'Guatemala',
    code: 'GT',
  },
  {
    id: 4305,
    name: 'Haiti',
    code: 'HT',
  },
  {
    id: 4514,
    name: 'Honduras',
    code: 'HN',
  },
  {
    id: 7033,
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    id: 6845,
    name: 'India',
    code: 'IN',
  },
  {
    id: 6877,
    name: 'Indonesia',
    code: 'ID',
  },
  {
    id: 6976,
    name: 'Jordan',
    code: 'JO',
  },
  {
    id: 4850,
    name: 'Lao People`s Democratic Republic',
    code: 'LA',
  },
  {
    id: 4877,
    name: 'Liberia',
    code: 'LR',
  },
  {
    id: 4978,
    name: 'Madagascar',
    code: 'MG',
  },
  {
    id: 5015,
    name: 'Malaysia',
    code: 'MY',
  },
  {
    id: 5156,
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    id: 5619,
    name: 'Mexico',
    code: 'MX',
  },
  {
    id: 5527,
    name: 'Mongolia',
    code: 'MN',
  },
  {
    id: 5606,
    name: 'Morocco',
    code: 'MA',
  },
  {
    id: 7075,
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    id: 5767,
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    id: 3389,
    name: 'Oman',
    code: 'OM',
  },
  {
    id: 3589,
    name: 'Pakistan',
    code: 'PK',
  },
  {
    id: 5826,
    name: 'Palau',
    code: 'PW',
  },
  {
    id: 3635,
    name: 'Panama',
    code: 'PA',
  },
  {
    id: 3567,
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    id: 3600,
    name: 'Paraguay',
    code: 'PY',
  },
  {
    id: 5558,
    name: 'Peru',
    code: 'PE',
  },
  {
    id: 4131,
    name: 'Philippines',
    code: 'PH',
  },
  {
    id: 4917,
    name: 'Rwanda',
    code: 'RW',
  },
  {
    id: 6053,
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    id: 5511,
    name: 'Samoa',
    code: 'WS',
  },
  {
    id: 6024,
    name: 'Seychelles',
    code: 'SC',
  },
  {
    id: 6645,
    name: 'Singapore',
    code: 'SG',
  },
  {
    id: 7041,
    name: 'South Korea',
    code: 'KR',
  },
  {
    id: 6057,
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    id: 6056,
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    id: 6121,
    name: 'Suriname',
    code: 'SR',
  },
  {
    id: 7043,
    name: 'Taiwan',
    code: 'TW',
  },
  {
    id: 7089,
    name: 'Tanzania',
    code: 'TZ',
  },
  {
    id: 6163,
    name: 'Thailand',
    code: 'TH',
  },
  {
    id: 6277,
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    id: 6544,
    name: 'Uruguay',
    code: 'UY',
  },
  {
    id: 6396,
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    id: 6991,
    name: 'Vietnam',
    code: 'VN',
  },
  {
    id: 6996,
    name: 'Zambia',
    code: 'ZM',
  },
  {
    id: 5748,
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    id: 3809,
    name: 'Belize',
    code: 'BZ',
  },
  {
    id: 6713,
    name: 'Jamaica',
    code: 'JM',
  },
  {
    id: 7069,
    name: 'Macao',
    code: 'MO',
  },
  {
    id: 7059,
    name: 'French Guiana',
    code: 'GF',
  },
  {
    id: 5618,
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    id: 4127,
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    id: 5010,
    name: 'Malawi',
    code: 'MW',
  },
  {
    id: 4137,
    name: 'Fiji',
    code: 'FJ',
  },
  {
    id: 4254,
    name: 'Guyana',
    code: 'GY',
  },
  {
    id: 6268,
    name: 'Tonga',
    code: 'TO',
  },
  {
    id: 7092,
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    id: 3423,
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    id: 5860,
    name: 'Japan',
    code: 'JP',
  },
  {
    id: 5161,
    name: 'Mauritania',
    code: 'MR',
  },
  {
    id: 5751,
    name: 'Nepal',
    code: 'NP',
  },
  {
    id: 6647,
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    id: 6133,
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    id: 3340,
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    id: 3416,
    name: 'Egypt',
    code: 'EG',
  },
  {
    id: 3665,
    name: 'Belarus',
    code: 'BY',
  },
  {
    id: 3685,
    name: 'North Mariana',
    code: 'MP',
  },
  {
    id: 3715,
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    id: 3817,
    name: 'Bhutan',
    code: 'BT',
  },
  {
    id: 3865,
    name: 'Burundi',
    code: 'BI',
  },
  {
    id: 3959,
    name: 'Timor Leste',
    code: 'TL',
  },
  {
    id: 3983,
    name: 'Russia',
    code: 'RU',
  },
  {
    id: 4093,
    name: 'Eritrea',
    code: 'ER',
  },
  {
    id: 4233,
    name: 'Cuba',
    code: 'CU',
  },
  {
    id: 4253,
    name: 'Guam',
    code: 'GU',
  },
  {
    id: 4535,
    name: 'Kiribati',
    code: 'KI',
  },
  {
    id: 4564,
    name: 'Guinea',
    code: 'GN',
  },
  {
    id: 4574,
    name: 'Guinea Bissau',
    code: 'GW',
  },
  {
    id: 4809,
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    id: 4869,
    name: 'Lebanon',
    code: 'LB',
  },
  {
    id: 4896,
    name: 'Libya',
    code: 'LY',
  },
  {
    id: 4986,
    name: 'Maldives',
    code: 'MV',
  },
  {
    id: 5144,
    name: 'Mali',
    code: 'ML',
  },
  {
    id: 5176,
    name: 'United States',
    code: 'US',
  },
  {
    id: 5557,
    name: 'Micronesia',
    code: 'FM',
  },
  {
    id: 5589,
    name: 'Myanmar',
    code: 'MM',
  },
  {
    id: 5750,
    name: 'Nauru',
    code: 'NR',
  },
  {
    id: 6110,
    name: 'Sudan',
    code: 'SD',
  },
  {
    id: 6145,
    name: 'Somalia',
    code: 'SO',
  },
  {
    id: 6146,
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    id: 6278,
    name: 'Tunisia',
    code: 'TN',
  },
  {
    id: 6304,
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    id: 6432,
    name: 'Venezuela',
    code: 'VE',
  },
  {
    id: 6517,
    name: 'Ukraine',
    code: 'UA',
  },
  {
    id: 6695,
    name: 'Syria',
    code: 'SY',
  },
  {
    id: 6765,
    name: 'Iraq',
    code: 'IQ',
  },
  {
    id: 6766,
    name: 'Iran',
    code: 'IR',
  },
  {
    id: 7025,
    name: 'Central African',
    code: 'CF',
  },
  {
    id: 7027,
    name: 'American Samoa',
    code: 'AS',
  },
  {
    id: 7030,
    name: 'Congo Democratic',
    code: 'CD',
  },
  {
    id: 7036,
    name: 'North Korea',
    code: 'KP',
  },
  {
    id: 7037,
    name: 'Palestinian',
    code: 'PS',
  },
  {
    id: 7039,
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    id: 7040,
    name: 'Saint Maarten',
    code: 'SX',
  },
  {
    id: 7040,
    name: 'Saint Maarten',
    code: 'SX',
  },
  {
    id: 7042,
    name: 'South Sudan',
    code: 'SS',
  },
  {
    id: 7050,
    name: 'Aruba',
    code: 'AW',
  },
  {
    id: 7052,
    name: 'British Indian Ocean',
    code: 'IO',
  },
  {
    id: 7055,
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    id: 7056,
    name: 'Cocos Islands',
    code: 'CC',
  },
  {
    id: 7058,
    name: 'Falkland Islands',
    code: 'FK',
  },
  {
    id: 7060,
    name: 'French Southern Territory',
    code: 'TF',
  },
  {
    id: 7064,
    name: 'Heard Island And Mcdonald Islands',
    code: 'HM',
  },
  {
    id: 7074,
    name: 'Netherlands Antilles',
    code: 'AN',
  },
  {
    id: 7076,
    name: 'Niue',
    code: 'NU',
  },
  {
    id: 7077,
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    id: 7079,
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    id: 7084,
    name: 'Saint Pierre And Miquelon',
    code: 'PM',
  },
  {
    id: 7091,
    name: 'Tokelau',
    code: 'TK',
  },
  {
    id: 6431,
    name: 'Virgin Islands',
    code: 'VG',
  },
  {
    id: 7096,
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    id: 7047,
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    id: 3554,
    name: 'Austria',
    code: 'AT',
  },
  {
    id: 3701,
    name: 'Belgium',
    code: 'BE',
  },
  {
    id: 4688,
    name: 'Cameroon',
    code: 'CM',
  },
  {
    id: 7057,
    name: 'Croatia',
    code: 'HR',
  },
  {
    id: 6016,
    name: 'Cyprus',
    code: 'CY',
  },
  {
    id: 3899,
    name: 'Denmark',
    code: 'DK',
  },
  {
    id: 3464,
    name: 'Estonia',
    code: 'EE',
  },
  {
    id: 4138,
    name: 'Finland',
    code: 'FI',
  },
  {
    id: 4101,
    name: 'France',
    code: 'FR',
  },
  {
    id: 5859,
    name: 'Georgia',
    code: 'GE',
  },
  {
    id: 3915,
    name: 'Germany',
    code: 'DE',
  },
  {
    id: 4611,
    name: 'Ghana',
    code: 'GH',
  },
  {
    id: 6633,
    name: 'Greece',
    code: 'GR',
  },
  {
    id: 3714,
    name: 'Iceland',
    code: 'IS',
  },
  {
    id: 3436,
    name: 'Ireland',
    code: 'IE',
  },
  {
    id: 6777,
    name: 'Italy',
    code: 'IT',
  },
  {
    id: 4266,
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    id: 7034,
    name: 'Kosovo',
    code: 'XK',
  },
  {
    id: 4912,
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    id: 5155,
    name: 'Macedonia',
    code: 'MK',
  },
  {
    id: 5009,
    name: 'Malta',
    code: 'MT',
  },
  {
    id: 5681,
    name: 'Namibia',
    code: 'NA',
  },
  {
    id: 4482,
    name: 'NETHERLANDS',
    code: 'NL',
  },
  {
    id: 5796,
    name: 'Nigeria',
    code: 'NG',
  },
  {
    id: 5804,
    name: 'Norway',
    code: 'NO',
  },
  {
    id: 3716,
    name: 'Poland',
    code: 'PL',
  },
  {
    id: 5828,
    name: 'Portugal',
    code: 'PT',
  },
  {
    id: 7083,
    name: 'Saint Martin',
    code: 'MF',
  },
  {
    id: 6084,
    name: 'Slovakia',
    code: 'SK',
  },
  {
    id: 6094,
    name: 'Slovenia',
    code: 'SI',
  },
  {
    id: 6581,
    name: 'Spain',
    code: 'ES',
  },
  {
    id: 6305,
    name: 'Turkey',
    code: 'TR',
  },
  {
    id: 6109,
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    id: 5909,
    name: 'Sweden',
    code: 'SE',
  },
  {
    id: 5932,
    name: 'Switzerland',
    code: 'CH',
  },
  {
    id: 3382,
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    id: 6907,
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    id: 3481,
    name: 'Andorra',
    code: 'AD',
  },
  {
    id: 3490,
    name: 'Angola',
    code: 'AO',
  },
  {
    id: 3624,
    name: 'Bahrain',
    code: 'BH',
  },
  {
    id: 3674,
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    id: 3808,
    name: 'Botswana',
    code: 'BW',
  },
  {
    id: 4661,
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    id: 4735,
    name: 'Kuwait',
    code: 'KW',
  },
  {
    id: 4759,
    name: 'Kenya',
    code: 'KE',
  },
  {
    id: 4810,
    name: 'Latvia',
    code: 'LV',
  },
  {
    id: 4897,
    name: 'Lithuania',
    code: 'LT',
  },
  {
    id: 5605,
    name: 'Moldova',
    code: 'MD',
  },
  {
    id: 6673,
    name: 'Hungary',
    code: 'HU',
  },
  {
    id: 6767,
    name: 'Israel',
    code: 'IL',
  },
  {
    id: 4700,
    name: 'Qatar',
    code: 'QA',
  },
  {
    id: 4935,
    name: 'Romania',
    code: 'RO',
  },
  {
    id: 5696,
    name: 'South Africa',
    code: 'ZA',
  },
  {
    id: 5987,
    name: 'Serbia',
    code: 'RS',
  },
  {
    id: 6025,
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    id: 6459,
    name: 'Uganda',
    code: 'UG',
  },
  {
    id: 4624,
    name: 'Gabon',
    code: 'GA',
  },
  {
    id: 3686,
    name: 'Benin',
    code: 'BJ',
  },
  {
    id: 4838,
    name: 'Lesotho',
    code: 'LS',
  },
  {
    id: 6003,
    name: 'Senegal',
    code: 'SN',
  },
  {
    id: 7048,
    name: 'Aland Islands',
    code: 'AX',
  },
  {
    id: 7081,
    name: 'Saint Barthelemy',
    code: 'BL',
  },
  {
    id: 3399,
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    id: 3974,
    name: 'Togo',
    code: 'TG',
  },
  {
    id: 5160,
    name: 'Mauritius',
    code: 'MU',
  },
  {
    id: 7062,
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    id: 7070,
    name: 'Martinique',
    code: 'MQ',
  },
  {
    id: 7080,
    name: 'Reunion',
    code: 'RE',
  },
  {
    id: 7070,
    name: 'Martinique',
    code: 'MQ',
  },
  {
    id: 3818,
    name: 'Burkina Fasa',
    code: 'BF',
  },
  {
    id: 4231,
    name: 'Grenada',
    code: 'GD',
  },
  {
    id: 4232,
    name: 'Greenland',
    code: 'GL',
  },
  {
    id: 5617,
    name: 'Monaco',
    code: 'MC',
  },
  {
    id: 7049,
    name: 'Anguilla',
    code: 'AI',
  },
  {
    id: 7061,
    name: 'Gilbraltar',
    code: 'GI',
  },
  {
    id: 7066,
    name: 'Jersey',
    code: 'JE',
  },
  {
    id: 6565,
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    id: 7063,
    name: 'Guernsey',
    code: 'GG',
  },
  {
    id: 7065,
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    id: 7071,
    name: 'Mayotte',
    code: 'YT',
  },
  {
    id: 3276,
    name: 'Albania',
    code: 'AL',
  },
  {
    id: 3795,
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    id: 4546,
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    id: 7008,
    name: 'Chad',
    code: 'TD',
  },
  {
    id: 7031,
    name: 'Congo Republic',
    code: 'CG',
  },
  {
    id: 7044,
    name: 'Vatican City State',
    code: 'VA',
  },
  {
    id: 7045,
    name: 'Cote DIvoire',
    code: 'CI',
  },
  {
    id: 3673,
    name: 'Bermuda',
    code: 'BM',
  },
  {
    id: 4126,
    name: 'Faroe Island',
    code: 'FO',
  },
  {
    id: 4183,
    name: 'Gambia',
    code: 'GM',
  },
  {
    id: 4540,
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    id: 4713,
    name: 'Comoros',
    code: 'KM',
  },
  {
    id: 4910,
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    id: 5786,
    name: 'Niger',
    code: 'NE',
  },
  {
    id: 5997,
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    id: 6050,
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    id: 6054,
    name: 'San Marino',
    code: 'SM',
  },
  {
    id: 6385,
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    id: 7032,
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    id: 7035,
    name: 'Montenegro',
    code: 'ME',
  },
  {
    id: 7051,
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    id: 7073,
    name: 'Montserrat',
    code: 'MS',
  },
  {
    id: 7082,
    name: 'Saint Helena',
    code: 'SH',
  },
  {
    id: 7085,
    name: 'South Georgia and The South Sandwich Islands',
    code: 'GS',
  },
  {
    id: 7087,
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    id: 4676,
    name: 'The Republic of Zimbabwe',
    code: 'ZW',
  },
  {
    id: 6742,
    name: 'The Republic of Yemen',
    code: 'YE',
  },
]
