export default {
  BankName: 'common.field.bankName',
  'Account Name': 'common.field.accName',
  'Account Number': 'common.field.accNum',
  accounttype: 'common.field.accType',
  accountdigit: 'common.field.accDigit',
  documenttype: 'common.field.documentType',
  'Document ID': 'common.field.docID',
  'Bank Branch': 'withdraw.BankBranch',
  'Bank Account Name': 'withdraw.BankAccountsName',
  'Bank Account Number': 'withdraw.BankAccountNumber',
  'Neteller Account Name': 'common.field.netellerAccName',
  'Neteller Email Address': 'common.field.netellerEmailAddress',
  'Skrill Account Name': 'common.field.skrillAccName',
  'Skrill Email Address': 'common.field.skrillEmailAddress',
  'EBuy Account Name': 'common.field.ebuyAccName',
  'EBuy Email Address': 'common.field.ebuyEmailAddress',
  'PIX Account Name': 'common.field.paymentAccName',
  'PIX Account Number': 'common.field.paymentAccNum',
  'Fasapay Account Name': 'common.field.paymentAccName',
  'Fasapay Account Number': 'common.field.paymentAccNum',
  'Branch Code': 'common.field.bankBranchCode',
  'Bank Account Name in Katakana': 'common.field.bankAccNumKate',
  EVP: 'withdraw.default.evp',
  PHONE: 'withdraw.default.phone',
  'SWIFT Code': 'common.field.swiftCode',
  'Bank Name': 'common.field.bankName',
  'Bank Address': 'common.field.bankAddress',
  'Account Holder’s Address': 'common.field.bankHolderAddress',
  'Bank Beneficiary Name': 'common.field.bankBeneficiaryName',
  'Bank Account Number/IBAN': 'common.field.bankAccNum',
}
