<template>
  <el-form :model="form" label-position="top" :rules="rulesPerfectMoney" ref="withdrawFormPerfectMoney">
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.PerfectMoneyEmail')" prop="perfectMoneyEmail">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.perfectMoneyEmail"
              auto-complete="off"
              data-testid="perfectMoneyEmail"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.PerfectMoneyAccount')" prop="accountNumber">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.accountNumber"
              auto-complete="off"
              data-testid="accountNumber"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateEmail, validateAlphaAndNumeric } from '@/util/validators';

export default {
  data() {
    return {
      form: {
        perfectMoneyEmail: '',
        accountNumber: '',
        importantNotes: ''
      },
      rulesPerfectMoney: {
        perfectMoneyEmail: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('withdraw.PerfectMoneyEmail')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('withdraw.PerfectMoneyAccount')
            }),
            trigger: 'blur'
          },
          {
            validator: validateAlphaAndNumeric,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormPerfectMoney.validate();
    }
  }
};
</script>
