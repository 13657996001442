<template>
  <div>
    <el-form
      :model="withdrawForm"
      v-resizeObserver="{
        openResize: true,
        handler: observerCallBack
      }"
      label-position="top"
      :rules="rulesWithdraw"
      ref="withdrawForm"
    >
      <div class="form-list-inner">
        <p>{{ $t('common.keys.FROM') }}</p>
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('common.keys.rebateAccount')" prop="qAccount">
              <el-select v-model="withdrawForm.qAccount" data-testid="rebateAccount" @change="rebateAccountChange">
                <el-option
                  v-for="item in accountList"
                  :key="item.login"
                  :label="item.login + ' - ' + item.balance + ' ' + item.currency"
                  :value="item.login"
                  :data-testid="item.login"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="amtLabel" prop="amount">
              <numeric-input
                ref="numberInput"
                v-model="withdrawForm.amount"
                :currency="withdrawForm.currency"
                :precision="2"
              ></numeric-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner to">
        <ul class="clearfix">
          <li class="topTo">
            <p>{{ $t('common.keys.TO') }}</p>
            <el-form-item :label="$t('withdraw.PleaseSelectDesiredWithdrawMethod')">
              <div class="mobile-title" v-if="!isBigDevicesMatches">{{ TABS_ITEMS[selectedTab].title }}</div>
              <el-tabs v-model="selectedTab" :stretch="true" type="card" v-show="isBigDevicesMatches">
                <el-tab-pane
                  v-for="(tab, index) in Object.values(TABS_ITEMS)"
                  :key="index"
                  :label="tab.title"
                  :name="tab.name"
                >
                  <withdrawTypeList
                    class="item"
                    v-for="item in getType(tab.name)"
                    :key="item.id"
                    :type="item"
                    :name="tab.testId"
                    :selectedType="withdrawForm.withdrawalType"
                    :isShow="isBigDevicesMatches"
                    :countryCode="countryCode"
                    @changeType="changeType"
                  ></withdrawTypeList>
                </el-tab-pane>
              </el-tabs>
              <!-- mobile -->
              <el-tabs v-model="selectedTab" :stretch="true" type="card" v-show="!isBigDevicesMatches">
                <el-tab-pane
                  v-for="(mobileTab, index) in Object.values(TABS_ITEMS)"
                  :key="index"
                  :name="mobileTab.name"
                >
                  <div slot="label">
                    <img
                      :src="
                        require(`@/assets/images/rebateWithdraw/${mobileTab.img}${
                          mobileTab.name === selectedTab ? '_active' : ''
                        }.png`)
                      "
                      :alt="mobileTab.name"
                      :data-testid="mobileTab.name"
                    />
                  </div>
                  <withdrawTypeList
                    class="item"
                    v-for="item in getType(mobileTab.name)"
                    :key="item.id"
                    :type="item"
                    :name="mobileTab.testId"
                    :selectedType="withdrawForm.withdrawalType"
                    :isShow="!isBigDevicesMatches"
                    :countryCode="countryCode"
                    @changeType="changeType"
                  ></withdrawTypeList>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>
          </li>
        </ul>
      </div>

      <InfoDialog
        :visible.sync="showIbtDialog"
        :title="dialogContent[resInfoType].title"
        :infoType="resInfoType"
        @closCbDialog="showIbtDialog = false"
      >
        <div>{{ dialogContent[resInfoType].content }}</div>

        <template #actions>
          <Button buttonType="default" class="dialog-action-button" @click="goToPage(redirectPage)">
            {{ dialogContent[resInfoType].button }}
          </Button>
          <Button
            v-if="resInfoType === 'fail'"
            buttonType="default"
            outline
            class="dialog-action-button"
            @click="showIbtDialog = false"
          >
            {{ $t('register.btn.withdrawViaOther') }}
          </Button>
        </template>
      </InfoDialog>
    </el-form>
    <el-dialog
      class="withdraw_dialog"
      :title="$t('common.withdrawChannel.limitTitle')"
      :visible.sync="visible"
      width="400px"
      center
      :show-close="true"
    >
      <div style="text-align: center">
        {{
          fromFunctionCode == 'updatePassword'
            ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
            : fromFunctionCode == 'changeAuthenticator'
            ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
            : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
        }}
      </div>
      <div slot="footer" class="dialog-footer btn-box">
        <el-button style="width: 100%" @click="visible = false" class="btn-blue">{{
          $t('common.button.iKnow')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import withdrawTypeList from '@/components/rebateWithdraw/withdrawTypeList/withdrawTypeList.vue';
import Button from '@/components/common/Button.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiGetWithdrawalData, apiQueryRateUSDByTargetCurrency, apiGetSecuritys } from '@/resource';
import { apiGetCpsWithdrawalChannel } from '@/resource/cps';
import { countryCodeEnum } from '@/constants/country';
import { countryIsoCode } from '@/constants/countryCode';
import signHelper from '@/util/signinHelper';
import {
  CURRENCY_RESTRICTED_CHANNELS,
  MULTIPLE_CURRENCY_CHANNELS,
  CURRENCY_AVAILABLE_TYPES
} from '@/constants/withdrawChannelCode';
import { mapActions, mapState } from 'vuex';
import { statusConfig } from '@/constants/status';
import { REGISTERROUTER } from '@/constants/route';
import { withdrawListingIndex } from '@/constants/withdrawListingIndex';

export default {
  name: 'top',
  components: { NumericInput, withdrawTypeList, InfoDialog, Button },
  props: { chooseWithdrawalType: Object },
  data() {
    return {
      fromFunctionCode: '',
      day: '',
      hour: '',
      visible: false,
      limitTypes: [],
      selectedTab: 'bankTransfer',
      TABS_ITEMS: {
        cryptoCurrency: {
          title: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
          img: `crypto_mobile`,
          name: `cryptoCurrency`,
          testId: 'cryptoWithdrawal-'
        },
        localTransfer: {
          title: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
          img: `lt_mobile`,
          name: `localTransfer`,
          testId: 'localWithdrawal-'
        },
        eWallet: {
          title: this.$t('common.withdrawChannel.E_WALLET'),
          img: `ew_mobile`,
          name: `eWallet`,
          testId: 'eWalletWithdrawal-'
        },
        bankTransfer: {
          title: this.$t('common.withdrawChannel.banktransfer'),
          img: `bank_mobile`,
          name: `bankTransfer`,
          testId: 'bankWithdrawal-'
        }
      },
      accountList: [],
      withdrawalTypes: [],
      withdrawalSupportedTypes: [],
      countryBankList: null,
      countryCode: null,
      availableBalance: '',
      initialWithdrawalCall: false,
      withdrawForm: {
        qAccount: '',
        amount: '',
        currency: '',
        withdrawalType: ''
      },
      ngaCountryCode: 5796,
      canCountryCode: 4575,
      ngaSupportCurrencies: ['USD', 'USC'],
      excludeCurrency: ['USD', 'USC'],
      minLimit: this.$config.withdrawalDefaultAmountMin, // current minLimit
      apiAmountLimit: 0, // record amountLimit from props chooseWithdrawalType.amountLimit
      currentWidth: 0,
      showIbtDialog: false,
      resInfoType: 'success',
      redirectPage: { name: REGISTERROUTER.FINANCIAL_INFO },
      withdrawLimitArrays: []
    };
  },
  watch: {
    withdrawForm: {
      handler(val, oldVal) {
        this.$emit('withdrawFormChange', val);
      },
      deep: true
    },
    selectedTab: {
      handler(val, oldVal) {
        this.$emit('tabChange', val);
      }
    },
    chooseWithdrawalType: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.apiAmountLimit = newValue?.amountLimit;
          this.convertUSDToTargetCurrency(this.withdrawForm.currency);
        }
      }
    },
    'withdrawForm.currency': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.convertUSDToTargetCurrency(newValue);
          if (this.withdrawForm.amount) this.$refs.withdrawForm.clearValidate();
        }
      }
    },
    'withdrawForm.qAccount': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.getWithdrawalData();
        }
      }
    }
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    rulesWithdraw() {
      return {
        qAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.amt'),
            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (parseFloat(value) <= 0) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(new Error(this.$t('withdraw.formErrorMsg.amt0')));
                }
              } else if (value < this.minLimit) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(
                    new Error(
                      this.$t('withdraw.amtLarger', {
                        minLimit: this.minLimit,
                        currency: this.withdrawForm.currency
                      })
                    )
                  );
                }
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('withdraw.YourPaymentGreaterThanAvailableBalance')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      };
    },
    amtLabel() {
      return this.withdrawForm.currency === 'USC'
        ? `${this.$t('common.keys.amount')}    ${this.$t('common.keys.actualAmonut')}= ${this.withdrawForm.amount /
            100} USD`
        : this.$t('common.keys.amount');
    },
    bankWithdrawalType() {
      let bankTransferList = [];
      const bankTransferSupportedTypes = this.withdrawalSupportedTypes.filter(f => f.category === 1);

      if (bankTransferSupportedTypes.length > 0) {
        bankTransferSupportedTypes.forEach(f => {
          if (f.id === 2) {
            if (
              !this.$config.allowIBTWithdrawal({
                reg: this.regulator,
                countryCode: this.$store.state.common.countryCode,
                type: 'WITHDRAW'
              })
            ) {
              return [];
            } else {
              bankTransferList.push(f);
            }
          } else {
            bankTransferList.push(f);
          }
        });
        return bankTransferList;
      } else {
        return this.withdrawalSupportedTypes.filter(f => f.category === 1);
      }
    },
    eWalletWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 3);
    },
    cryptoWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 4);
    },
    localWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 2 || f.category === 5);
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    isBigDevicesMatches() {
      return this.currentWidth > 700;
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code;
    },
    dialogContent() {
      return {
        success: {
          // title: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.title'),
          title: '',
          content: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.content'),
          button: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.start')
        },
        pending: {
          title: this.$t('register.financialVerification.generalInfo.title'),
          content: this.$t('register.financialVerification.generalInfo.content'),
          button: this.$t('register.btn.withdrawViaOther')
        },
        fail: {
          title: this.$t('register.financialVerification.identifyingRequiredInfo.title'),
          content: this.$t('register.financialVerification.identifyingRequiredInfo.content'),
          button: this.$t('verificationStatus.kyc.resubmit')
        }
      };
    }
  },
  mounted() {
    this.getWithdrawalData();
    this.getSecuritys();
  },
  methods: {
    getWithdrawalData() {
      let body = { qUserId: this.$store.state.common.CUID };
      if (this.withdrawForm.qAccount) Object.assign(body, { accountNumber: this.withdrawForm.qAccount.toString() });

      apiGetWithdrawalData(body).then(resp => {
        if (resp.data.code === 0) {
          let res = [];
          const accounts = resp.data.data.logins;
          this.withdrawalTypes = resp.data.data.withdrawalType;
          res = resp.data.data.withdrawalType;

          this.countryCode = resp.data.data.countryCode;
          this.accountList = accounts.filter(acc => acc.accountDealType === 3);
          this.countryBankList = resp.data.data.withdrawBankList;

          this.$emit('setCountryCode', this.countryCode);
          this.$emit('setBankList', this.countryBankList);

          if (this.withdrawForm.qAccount === '' && this.accountList && this.accountList.length > 0) {
            this.$nextTick(() => {
              // 默认选中首页选中的账号
              this.$set(this.withdrawForm, 'qAccount', this.accountList[0].login);
              this.rebateAccountChange(this.accountList[0].login);
            });
          }

          if (this.initialWithdrawalCall) this.getCpsWitdrawalData([...res]);
        }
        // set initial withdrawal call
        this.initialWithdrawalCall = true;
      });
    },
    getCpsWitdrawalData(resData = []) {
      apiGetCpsWithdrawalChannel({ qAccount: this.withdrawForm.qAccount, country: this.getCountryIsoCode })
        .then(resp => {
          if (resp.status === 200) {
            this.withdrawalSupportedTypes = [...resData];
            this.filterWithdrawalSupportedTypes(this.withdrawForm.currency);

            // combine channels into withdrawal type list
            resp.data.data.withdrawalType.channels.forEach(m => {
              let merchantVariable = JSON.parse(m.merchant_variable);

              let withdrawalList = {
                amountLimit: merchantVariable.amountLimit,
                availableCurrencies: null,
                category: merchantVariable?.category ? parseInt(merchantVariable.category) : 1,
                id: m.withdrawTypeId,
                cps: m,
                nameKey: merchantVariable.description_title
              };
              //   // restrict payment channel currencies
              this.restrictPaymentChannelCurrencies(withdrawalList);
            });

            // // remove duplicates
            this.withdrawalSupportedTypes = [...new Set(this.withdrawalSupportedTypes)];

            // sort payment channel
            this.withdrawalSupportedTypes = this.sortPaymentChannel(this.withdrawalSupportedTypes);
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    restrictPaymentChannelCurrencies(payment) {
      // check if payment channel is under restricted list
      if (CURRENCY_RESTRICTED_CHANNELS.includes(payment.id)) {
        if (
          CURRENCY_AVAILABLE_TYPES.hasOwnProperty(this.withdrawForm.currency) &&
          CURRENCY_AVAILABLE_TYPES[this.withdrawForm.currency].includes(payment.id)
        ) {
          if (MULTIPLE_CURRENCY_CHANNELS.includes(payment.id)) {
            if (payment.cps.currency_code == this.withdrawForm.currency) {
              this.withdrawalSupportedTypes.push(payment);
            }
            if (payment.cps.currency_code === 'USD' && this.withdrawForm.currency === 'USC') {
              this.withdrawalSupportedTypes.push(payment);
            }
          } else {
            this.withdrawalSupportedTypes.push(payment);
          }
        }
      } else {
        this.withdrawalSupportedTypes.push(payment);
      }
    },
    sortPaymentChannel(withdrawTypes) {
      let withdrawListOrder = [];
      let sortedWithdrawList = [];

      withdrawListingIndex.forEach(wi => {
        if (wi.id == this.countryCode) {
          withdrawListOrder = wi.order;
        }
      });

      if (withdrawListOrder.length == 0) {
        withdrawListingIndex.forEach(wi => {
          if (wi.id == 'global') {
            withdrawListOrder = wi.order;
          }
        });
      }

      withdrawListOrder.forEach(wi => {
        withdrawTypes.forEach(withdrawType => {
          if (wi == withdrawType.id) {
            sortedWithdrawList.push(withdrawType);
          }
        });
      });

      const remainingPayments = withdrawTypes.filter(v => !withdrawListOrder.includes(v.id));
      sortedWithdrawList = sortedWithdrawList.concat(remainingPayments);

      // IBT channel need to put in last order
      const ibtChannelIndex = sortedWithdrawList.findIndex(sw => sw.id === 2);
      const [ibtChannel] = sortedWithdrawList.splice(ibtChannelIndex, 1);
      sortedWithdrawList.push(ibtChannel);

      return sortedWithdrawList;
    },
    rebateAccountChange(val) {
      if (val === '') {
        return;
      }

      const [currAccountInfo] = this.accountList.filter(item => {
        return item.login === val;
      });

      if (currAccountInfo) {
        this.availableBalance = currAccountInfo.balance;
        this.withdrawForm.currency = currAccountInfo.currency;
      }

      this.withdrawForm.withdrawalType = null;

      // this.filterWithdrawalSupportedTypes(this.withdrawForm.currency);

      this.$nextTick(() => {
        if (this.withdrawForm.amount) {
          this.$refs.withdrawForm.validateField('amount', valid => {
            if (!valid) {
              return false;
            }
          });
        }
      });
    },
    isLimitedTypes(type) {
      const currLimitObj = this.withdrawLimitArrays.find(item => {
        let curr = item.limitWithdrawTypes ? item.limitWithdrawTypes.split(',').map(item => Number(item)) : [];
        return curr.includes(type);
      });

      if (currLimitObj && Object.keys(currLimitObj).length > 0) {
        this.fromFunctionCode = currLimitObj.fromFunctionCode;
        this.day = 'T + ' + currLimitObj.limitDayNumber;
        this.hour = currLimitObj.lastHours;
        this.limitTypes = currLimitObj.limitWithdrawTypes
          ? currLimitObj.limitWithdrawTypes.split(',').map(item => Number(item))
          : [];
        return true;
      } else {
        return false;
      }
      // return this.limitTypes.includes(type);
    },
    async getSecuritys() {
      await apiGetSecuritys().then(res => {
        if (res.data.code == 0 && res.data.data && res.data.data.length > 0) {
          let data = res.data.data;
          // 按时间排序
          this.withdrawLimitArrays = data
            .filter(el => el.functionCode == 'withdraw')
            .sort((a, b) => b.limitTimeUTC - a.limitTimeUTC);
        }
      });
    },
    filterWithdrawalSupportedTypes(currency) {
      this.withdrawalSupportedTypes = this.withdrawalTypes;

      if (!this.excludeCurrency.includes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => ![4, 9, 64].includes(type.id));
      }
      if (this.filterNgaWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 2);
      }
      if (this.filterCanWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 60);
      }
      // filter Perfect Money (id = 61)
      if (this.filterPerfectMoneyType(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 61);
      }
      if (this.filterIbtWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 2);
      }
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    isChangeBKImg(id) {
      //change svg nigeria bnak transfer
      return id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode;
    },
    filterNgaWithdrawTypes(currency) {
      return (
        this.regulator === 'svg' &&
        this.countryCode == this.ngaCountryCode &&
        !this.ngaSupportCurrencies.includes(currency)
      );
    },
    filterCanWithdrawTypes(currency) {
      return this.countryCode == this.canCountryCode && currency !== 'CAD';
    },
    filterPerfectMoneyType(currency) {
      const allowedCurrencies = ['USD', 'USC', 'EUR'];
      return currency ? !allowedCurrencies.includes(currency) : true;
    },
    filterIbtWithdrawTypes(currency) {
      const restrictedCountries = ['6777', '6907', '3701', '4575', '3899', '6581']; // Italy, United Kingdom, Belgium, Canada, Denmark, Spain
      const allowedCurrencies = {
        default: ['USC', 'USD', 'EUR', 'AUD', 'GBP', 'CAD'],
        spain: ['USD', 'EUR', 'AUD', 'GBP', 'CAD']
      };
      const isRestrictedCountry = restrictedCountries.includes(this.countryCode);
      const isSpain = this.countryCode === '6581'; //Spain: 6581
      const allowedCurrency = isSpain ? allowedCurrencies.spain : allowedCurrencies.default;

      return this.regulator === 'svg' && isRestrictedCountry && !allowedCurrency.includes(currency);
    },
    convertUSDToTargetCurrency(currency, equalValue = this.$config.withdrawalDefaultAmountMin) {
      if (this.countryCode == 4759) {
        switch (currency) {
          case 'JPY':
            this.minLimit = 1500;
            break;
          case 'HKD':
            this.minLimit = 80;
            break;
          case 'USC':
            this.minLimit = 1000;
            break;
          default:
            this.minLimit = 10;
            break;
        }
        return;
      }
      if (!this.$config.usdRateFromApiCurrencyList.includes(currency)) {
        this.minLimit = this.apiAmountLimit ? this.apiAmountLimit : equalValue;
        return;
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minLimit = Math.ceil(equalValue * Number(res.data.data));
          } else {
            this.minLimit = equalValue;
          }
        })
        .catch(error => {
          this.minLimit = equalValue;
        });
    },
    observerCallBack(width) {
      this.currentWidth = width;
    },
    getType(name) {
      switch (name) {
        case 'bankTransfer':
          return this.bankWithdrawalType;
        case 'eWallet':
          return this.eWalletWithdrawalType;
        case 'cryptoCurrency':
          return this.cryptoWithdrawalType;
        case 'localTransfer':
          return this.localWithdrawalType;
      }
    },
    changeType(val) {
      if (val?.cps) {
        this.cpsSelect(val.cps);
        this.$emit('setIsCps', true);
      } else {
        this.$emit('setIsCps', false);
      }

      // this.withdrawForm.withdrawalType = val.id;

      // show ibt dialog if not complete ibt poi & ibt poa
      if (val?.id == 2) {
        const res = this.checkIBTStatusAndShowDialog();
        if (res) this.withdrawForm.withdrawalType = val.id;
      } else if (this.isLimitedTypes(val.id)) {
        this.visible = true;
      } else {
        this.withdrawForm.withdrawalType = val.id;
      }
    },
    cpsSelect(payment) {
      this.$store.dispatch('cps/actionSetWithdrawalPayment', payment);
    },
    checkIBTStatusAndShowDialog() {
      // complete status of both IBT poi, poa
      if (this.ibtPoiAuditStatus === statusConfig.completed && this.ibtPoaAuditStatus === statusConfig.completed)
        return true;

      // if IBT poi is submitted or reaudit
      if (this.ibtPoiAuditStatus === statusConfig.submitted || this.ibtPoiAuditStatus === statusConfig.reaudit) {
        // submitted or reaudit status of both IBT poi, poa
        if (
          this.ibtPoaAuditStatus === statusConfig.submitted ||
          this.ibtPoaAuditStatus === statusConfig.reaudit ||
          this.ibtPoaAuditStatus === statusConfig.completed
        ) {
          this.resInfoType = 'pending';
          this.redirectPage = null;
          this.showIbtDialog = true;
          return false;
        }
        if (this.ibtPoaAuditStatus === statusConfig.pending || this.ibtPoaAuditStatus === statusConfig.rejected) {
          this.resInfoType = 'fail';
          this.redirectPage = { name: REGISTERROUTER.ADDRESS_PROOF };
          this.showIbtDialog = true;
          return false;
        }
      }

      if (this.ibtPoiAuditStatus === statusConfig.completed) {
        if (this.ibtPoaAuditStatus === statusConfig.submitted || this.ibtPoaAuditStatus === statusConfig.reaudit) {
          this.resInfoType = 'pending';
          this.redirectPage = null;
          this.showIbtDialog = true;
          return false;
        } else {
          this.resInfoType = this.ibtPoaAuditStatus === statusConfig.init ? 'success' : 'fail';
          this.redirectPage = { name: REGISTERROUTER.ADDRESS_PROOF };
          this.showIbtDialog = true;
          return false;
        }
      }

      if (this.ibtPoiAuditStatus === statusConfig.init && this.ibtPoaAuditStatus === statusConfig.init) {
        this.resInfoType = 'success';
        this.redirectPage = { name: REGISTERROUTER.FINANCIAL_INFO };
        this.showIbtDialog = true;
        return false;
      }

      // rejected or pending IBT poi
      if (this.ibtPoiAuditStatus === statusConfig.pending || this.ibtPoiAuditStatus === statusConfig.rejected) {
        this.resInfoType = 'fail';
        this.redirectPage = { name: REGISTERROUTER.ID_PROOF };
        this.showIbtDialog = true;
        return false;
      }

      return false;
    },
    goToPage(destination) {
      if (destination) this.$router.push(destination);
      this.showIbtDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-action-button {
  & + & {
    margin-top: 16px;
  }
}
.withdraw_dialog {
  /deep/ .el-dialog__header {
    padding: 61px 61px 0 61px;
  }
  /deep/ .el-dialog__body {
    padding: 0 61px;
    margin-top: 42px;
    margin-bottom: 48px;
  }
  /deep/ .el-dialog__footer {
    padding: 0 61px 61px 61px;
  }
  /deep/ .el-dialog__headerbtn {
    display: inline-block;
    font-size: 20px;
    .el-dialog__close {
      font-weight: bolder;
      color: $black;
    }
  }
  .word_break {
    word-break: normal;
  }
}
</style>
