<template>
  <el-form :model="form" :rules="TransferFormRules" ref="withdrawFormMB" class="form_bottom_row clearfix">
    <div class="form-list-inner">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.default.selectBA')" prop="selectedCardID">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div v-if="form.selectedCardID || form.selectedCardID === 0">
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-select v-model="form.bankName" :disabled="isdisabled" data-testid="bankName">
                <el-option
                  v-for="(value, name) in bankList"
                  :key="name"
                  :value="value"
                  :label="name"
                  :data-testid="name"
                ></el-option>
              </el-select>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankAccountNumber')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.Province')" prop="bankProvince">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankProvince"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankProvince"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.City')" prop="bankCity">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankCity"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankCity"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')" prop="importantNotes">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="notes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <ul class="clearfix" v-if="!isdisabled">
        <li>
          <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
            <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile" />
          </el-form-item>
        </li>
      </ul>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="form.isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
export default {
  props: {
    withdrawalType: Number,
    bankList: Object
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName: '',
        accountNumber: '',
        beneficiaryName: '',
        bankProvince: '',
        bankCity: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      fileList: [],
      isRememberInfo: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.province'),
            trigger: 'blur'
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.city'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);

      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.form = {
        ...this.form,
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        beneficiaryName: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        bankProvince: isCurrentCard ? selectedCardInfo.bankProvince : '',
        bankCity: isCurrentCard ? selectedCardInfo.bankCity : '',
        importantNotes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        isRememberInfo: isCurrentCard && selectedCardInfo.id ? '' : this.form.isRememberInfo,
        userPaymentInfoId: isCurrentCard && selectedCardInfo.id ? selectedCardInfo.id : ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormMB.validate();
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs.withdrawFormMB.validateField('uploadedFile');
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        bankProvince: this.form.bankProvince,
        bankCity: this.form.bankCity,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency,
        fileList: this.fileList
      };
    }
  }
};
</script>
