<template>
  <el-dialog id="confirmationDialog" :visible.sync="visible" @close="close" :close-on-click-modal="false">
    <div slot="title">
      <slot name="header">
        <p class="fw-800 f-16">
          {{ $t('security.verificationTitle') }}
        </p>
      </slot>
    </div>
    <div class="word_break">
      {{ $t('security.verificationDesc') }}
    </div>

    <div slot="footer">
      <el-button :class="showConfirm ? 'btn-default' : 'btn-blue'" @click="close">
        {{ showConfirm ? $t('ibProfile.sharedIbList.cancel') : $t('ibProfile.updateSharedIb.confirm') }}
      </el-button>
      <el-button v-if="showConfirm" class="btn-blue" @click="confirm">
        {{ $t('ibProfile.sharedIbList.enable') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    dialogVisible: {
      type: Boolean
    },
    showConfirm: {
      type: Boolean
    }
  },
  data() {
    return {
      visible: false
    };
  },

  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
      if ((bool = false)) {
        this.$emit('changeVisible', false);
      }
    }
  },
  methods: {
    close() {
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirm() {
      this.$emit('confirm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
#confirmationDialog {
  /deep/ .el-dialog {
    margin-top: 0vh !important;
    padding: 40px 50px;
    border-radius: 16px;
    text-align: center;
    max-width: 480px;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
    padding-bottom: 20px;
    // padding: 10px 20px 20px;
  }

  /deep/ .el-dialog__body {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    padding-bottom: 36px;
    // padding: 5px 20px 20px;
  }

  /deep/ .el-dialog__footer {
    // padding: 20px 30px 10px;
    padding: 0px;
    div {
      display: flex;
      gap: 20px;
      width: 100%;
      .el-button {
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        padding: 11px;
      }
    }
  }
  .word_break {
    word-break: normal;
  }
}
</style>
