import i18n from '@/lang/index'

// 整数或最多两位小数
const integerOrMaxTwoDecimalNumbersCreator = errorMsg => {
  return (rule, value, callback) => {
    const reg = /^\d+(\.\d{1,2})?$/
    if (!reg.test(value)) {
      callback(new Error(errorMsg))
    }
    callback()
  }
}

const validateEmail = errorMsg => {
  return (rule, value, callback) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value !== '' && !pattern.test(value)) callback(errorMsg)
    else callback()
  }
}

const validateAlphaAndNumeric = (rule, value, callback) => {
  const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
  if (!reg.test(value.toString())) {
    callback(new Error(i18n.t('common.formValidation.alphanumericReq')))
  } else {
    callback()
  }
}

export { integerOrMaxTwoDecimalNumbersCreator, validateEmail, validateAlphaAndNumeric }
