<template>
  <el-form :model="form" label-position="top" ref="withdrawFormChild">
    <div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <ImportantNotesInput
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></ImportantNotesInput>
            </el-form-item>
          </li>
        </ul>

        <p class="mb-2 text_red">
          {{ $t('withdraw.LBTchannelsNote') }}
        </p>
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  props: {
    withdrawalType: Number,
    bankList: Object
  },
  mixins: [withdrawlMixin, bankTransferMixin],
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      if (selectedCardInfo && selectedCardInfo.id !== -1) {
        this.form = {
          ...this.form,
          importantNotes: selectedCardInfo.importantNotes,
          userPaymentInfoId: selectedCardInfo.id ? selectedCardInfo.id : ''
        };
        this.isdisabled = true;
      } else {
        //choose another
        this.form = {
          ...this.form,
          importantNotes: '',
          userPaymentInfoId: ''
        };
        this.isdisabled = false;
      }
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
