import bigDecimal  from "js-big-decimal";
function rounding(func, number, prec) {
  var value = new bigDecimal(number);
  var pow = new bigDecimal(Math.pow(10, prec));
  var tempnumber = value.multiply(pow);
  tempnumber = func(tempnumber.value);
  return (tempnumber / Math.pow(10, prec)).toFixed(prec);
}

export default rounding;
