<template>
  <div
    :class="{ active: selectedType == type.id }"
    @click="click(type)"
    :data-testid="isShowComponent ? camalize(type.nameKey, type.id) : ''"
  >
    <div class="left"></div>
    <div class="right">
      <!-- <div class="logo" :class="isChangeBKImg(type.id) ? `img9` : `img${type.id}`"> -->
      <div class="logo" :class="getLogo(type.id)">
        <div class="img"></div>
      </div>
      <span>{{ getTitleLabel(type.nameKey) }}</span>
      <!-- CANADIAN BANKING ONLY -->
      <p class="bottom" v-if="isCanBank(type.id)">
        {{ $t('common.withdrawChannel.internetbankingcanadatm') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'withdrawTypeList',
  props: {
    type: {},
    selectedType: {},
    countryCode: 0,
    testId: '',
    isShow: {
      // PUG only, for preventing double data-testid on DOM
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowComponent() {
      return this.isShow;
    }
  },
  methods: {
    isChangeBKImg(id) {
      //change svg nigeria bnak transfer
      return id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode;
    },
    isCanBank(id) {
      return id == `60`;
    },
    getLogo(id) {
      switch (true) {
        case this.isChangeBKImg(id):
          return 'img9';
        case this.isCanBank(id):
          return `logoLg img${id}`;
        default:
          return `img${id}`;
      }
    },
    click(id) {
      this.$emit('changeType', id);
    },
    camalize(str, id) {
      str = str.replace('common.withdrawChannel.', '');
      return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) + '-' + id;
    },
    getTitleLabel(val) {
      if (this.$te(val) || this.$te(val, 'en_US')) {
        return this.$t(val);
      }
      return this.$options.filters.methods(val);
    }
  }
};
</script>
